/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import "user-variables";
@import 'theme/colors';
@import "theme/variables";
@import "theme/variables-dark";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

@import 'theme/maps';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'theme/_utilities';
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/root";
@import 'theme/root';

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import 'bootstrap';

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import 'theme/theme';

.content {
  padding: calc(var(--phoenix-navbar-top-height) + 1rem) 1.5rem 6.375rem 1.5rem;
}

dd, dt {
  //py-2
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  //mb-0
  margin-bottom: 0;
  //col-6
  flex: 0 0 auto !important;
  width: 50% !important;
  //text-truncate
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
  //border-bottom:
  //border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

dl dd:nth-of-type(2n+2), dl dt:nth-of-type(2n+2) {
  background-color: rgba(var(--phoenix-quaternary-bg-rgb), 0.1) !important;
}
