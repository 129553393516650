//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$white: #fff !default;
$gray-50: #f5f7fa !default; //(245, 247, 250)
$gray-100: #eff2f6 !default; //(239, 242, 246)
$gray-200: #e3e6ed !default; //(227, 230, 237)
$gray-300: #cbd0dd !default; //(203, 208, 221)
$gray-400: #9fa6bc !default; //(159, 166, 188)
$gray-500: #8a94ad !default; //(138, 148, 173)
$gray-600: #6e7891 !default; //(110, 120, 145)
$gray-700: #525b75 !default; //(82, 91, 117)
$gray-800: #3e465b !default; //(62, 70, 91)
$gray-900: #31374a !default; //(49, 55, 74)
$gray-1000: #222834 !default; //(34, 40, 52)
$gray-1100: #141824 !default; //(20, 24, 36)
$black: #000000 !default;

$grays: ( '50': $gray-50, '100': $gray-100, '200': $gray-200, '300': $gray-300, '400': $gray-400, '500': $gray-500, '600': $gray-600, '700': $gray-700, '800': $gray-800, '900': $gray-900, '1000': $gray-1000, '1100': $gray-1100 ) !default;

// fusv-disable
$blue-50: #f5f8ff !default;
$blue-100: #e5edff !default;
$blue-200: #adc5ff !default;
$blue-300: #85a9ff !default;
$blue-400: #6090ff !default;
$blue-500: #3874ff !default; // base-color
$blue-600: #004dff !default;
$blue-700: #003cc7 !default;
$blue-800: #0033aa !default;
$blue-900: #00267b !default;
$blue-1000: #00174d !default;

$red-50: #ffedeb !default;
$red-100: #ffe0db !default;
$red-200: #fabcb3 !default;
$red-300: #f48270 !default;
$red-400: #fb624a !default;
$red-500: #fa3b1d !default; // base-color
$red-600: #cc1b00 !default;
$red-700: #b81800 !default;
$red-800: #901400 !default;
$red-900: #630d00 !default;
$red-1000: #380700 !default;

$green-50: #f0fdec !default;
$green-100: #d9fbd0 !default;
$green-200: #bee8b4 !default;
$green-300: #90d67f !default;
$green-400: #51c035 !default;
$green-500: #008500 !default; // base-color
$green-600: #23890b !default;
$green-700: #1c6c09 !default;
$green-800: #115a00 !default;
$green-900: #0b3d00 !default;
$green-1000: #061f00 !default;

$orange-50: #fff6e0 !default;
$orange-100: #ffefca !default;
$orange-200: #ffe6ad !default;
$orange-300: #ffcc85 !default;
$orange-400: #ea9c3c !default;
$orange-500: #e5780b !default; // base-color
$orange-600: #d6630a !default;
$orange-700: #bc3803 !default;
$orange-800: #901400 !default;
$orange-900: #630d00 !default;
$orange-1000: #380700 !default;

$cyan-50: #f0faff !default;
$cyan-100: #c7ebff !default;
$cyan-200: #96d9ff !default;
$cyan-300: #60c6ff !default;
$cyan-400: #33acef !default;
$cyan-500: #0097eb !default; // base-color
$cyan-600: #0080c7 !default;
$cyan-700: #005585 !default;
$cyan-800: #004870 !default;
$cyan-900: #003a5b !default;
$cyan-1000: #002337 !default;


$primary:$green-500 !default;

$theme-hover-colors: ( 'primary': $green-600, 'secondary': $gray-1000, 'success': $green-600, 'info': $cyan-600, 'warning': $orange-600, 'danger': $red-600 ) !default;
$link-hover-color: $green-600 !default;
$font-size-base: 1.2rem !default;